<template>
  <section id="group-pricing-dose-distribuition">
    <b-tabs class="custom-tab-header">
      <b-tab
        v-for="(vacina, index) in vacinas"
        :key="vacina.id_vacina"
        :active="index == 0"
        title-link-class="font-tab"
        title-item-class="custom-tab-style"
      >
        <template #title>
          <h4 class="d-sm-inline m-0">{{ vacina.nome_tecnico }}</h4>
        </template>

        <GroupPricingDoseDistribuitionVacina
          :vacina="vacina"
          @groupComplete="setGroupComplete"
          @priceComplete="setPriceComplete"
          @habilitou="habilitou"
        />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BButton, BTab, BTabs, BCard } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";

import TabGroup from "./TabGroup.vue";
import TabPrecificacao from "./TabPrecificacao.vue";
import TabDistribuicao from "./TabDistribuicao.vue";
import GroupPricingDoseDistribuitionVacina from "./GroupPricingDoseDistribuitionVacina.vue";
import ButtonsSteps from "@/views/components/custom/ButtonsCampanha/ButtonSteps.vue";

import { CampanhaNacionalGetVacinasSelecionada } from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    FormWizard,
    TabContent,
    TabGroup,
    TabPrecificacao,
    TabDistribuicao,
    ButtonsSteps,
    GroupPricingDoseDistribuitionVacina,
  },

  data() {
    return {
      vacinas: [],
      groupComplete: [],
      priceComplete: [],
      visibilidadeVacina: [],
    };
  },

  methods: {
    changeTabGroup(vacina) {
      const groups = this.groupComplete.filter(
        (group) => group.id_vacina == vacina
      );
      return groups.length ? groups[0].completo : false;
    },
    changeTabPrice(vacina) {
      const price = this.priceComplete.filter(
        (group) => group.id_vacina == vacina
      );

      return price.length ? price[0].completo : false;
    },
    setGroupComplete(val) {
      this.groupComplete[val.id_vacina] = val;
    },
    setPriceComplete(val) {
      this.priceComplete[val.id_vacina] = val;
    },
    setVisibilidadeVacina(idVacina) {
      if (!this.visibilidadeVacina.includes(idVacina)) {
        this.visibilidadeVacina.push(idVacina);
      }
    },
    habilitou(idVacina) {
      this.$emit("habilitou", idVacina);
    },
  },
  mounted() {
    const vacinasSelecionadas = CampanhaNacionalGetVacinasSelecionada();
    if (vacinasSelecionadas != null) {
      this.vacinas = vacinasSelecionadas;
      this.vacinas.forEach((vacina, index) => {
        if (index == 0) {
          this.setVisibilidadeVacina(vacina.id_vacina);
        }
      });
    }
  },
  watch: {
    "$store.state.campanhaNacionalState.vacinas": {
      immediate: false,
      deep: true,
      handler(val) {
        this.vacinas = val;
      },
    },
  },
};
</script>

<style>
.textButton {
  margin-right: 8px;
  font-size: 1.1rem;
}

.borderButton {
  border-right: 1px solid #2772c052;
}

.adjustInput {
  margin-left: -4px;
}

.adjustIconInputValue {
  z-index: 100;
}
</style>
