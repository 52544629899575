<template>
  <div>
    <b-card class="shadow-none">
      <form-wizard
        color="#2772C0"
        :title="null"
        :subtitle="null"
        class="steps-transparent mb-3 col-9"
      >
        <tab-content
          title="Grupos"
          icon="feather icon-box"
          :before-change="() => changeTabGroup(vacina)"
        >
          <TabGroup :vacina="vacina" @groupComplete="setGroupComplete" />
        </tab-content>

        <tab-content
          title="Precificação"
          icon="feather icon-dollar-sign"
          :before-change="() => changeTabPrice(vacina)"
        >
          <TabPrecificacao
            v-if="visibilidadeTabPrecificacao.includes(vacina.id_vacina)"
            :vacina="vacina"
            @priceComplete="setPriceComplete"
          />
        </tab-content>

        <tab-content title="Distribuição" icon="feather icon-disc">
          <TabDistribuicao :vacina="vacina" />
        </tab-content>

        <template slot="footer" slot-scope="props">
          <ButtonsSteps :stepsData="props" />
        </template>
      </form-wizard>
    </b-card>
  </div>
</template>

<script>
import { BButton, BTab, BTabs, BCard } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import ButtonsSteps from "@/views/components/custom/ButtonsCampanha/ButtonSteps.vue";
import TabGroup from "./TabGroup.vue";
import TabPrecificacao from "./TabPrecificacao.vue";
import TabDistribuicao from "./TabDistribuicao.vue";
import { CampanhaNacionalGetPrecificacaoStatusCompleto } from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    FormWizard,
    TabContent,
    ButtonsSteps,
    TabGroup,
    TabPrecificacao,
    TabDistribuicao,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vacinas: [],
      groupComplete: [],
      priceComplete: [],
      visibilidadeTabPrecificacao: [],
      visibilidadeTabDistribuicao: [],
    };
  },
  mounted() {
    let desabilitado = {
      id_vacina: this.vacina.id_vacina,
      completo: false,
    };
    this.groupComplete[this.vacina.id_vacina] = desabilitado;
    this.priceComplete[this.vacina.id_vacina] = desabilitado;
    this.$emit("habilitou", this.vacina.id_vacina);
  },
  methods: {
    visibilidadeTab(tab, idVacina) {
      if (tab == "precificacao") {
        this.visibilidadeTabPrecificacao.push(idVacina);
      } else if (tab == "distribuicao") {
        this.visibilidadeTabDistribuicao.push(idVacina);
      }
    },
    changeTabGroup(vacina) {
      this.visibilidadeTab("precificacao", vacina.id_vacina);
      return this.groupComplete[vacina.id_vacina].completo;
    },
    changeTabPrice(vacina) {
      this.visibilidadeTab("distribuicao", vacina.id_vacina);
      return CampanhaNacionalGetPrecificacaoStatusCompleto(this.vacina.id_vacina);
    },
    setGroupComplete(val) {
      this.groupComplete[val.id_vacina] = val;
    },
    setPriceComplete(val) {
      this.priceComplete[val.id_vacina] = val;
    },
  },
};
</script>

